.header {
  display: flex;
  flex-flow: row;
  align-items: center;
  z-index: 3;
  @include media("<=medium") {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $background-color;
    padding: 2em;
  }
  .logo {
    display: flex;
    flex-flow: row;
    text-decoration: none;
    .circle {
      width: 45px;
      height: 45px;
      background-color: $color-blue;
      border-radius: 100%;
      text-align: center;
      color: $color-white;
      font-size: 1.7em;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text {
      margin-left: 0.5em;
      font-size: 1.5em;
      color: $color-white;
      font-weight: lighter;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .menu {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
    @include media("<=medium") {
      display: none;
    }
    .menu-item {
      padding: 0.5em 2em;
      .link {
        text-decoration: none;
        color: $text-color;
        &.active,
        &:hover {
          color: $color-white;
        }
        &.active {
          font-weight: bold;
        }
      }
    }
  }
  .menu-toggle {
    display: none;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
    cursor: pointer;
    @include media("<=medium") {
      display: flex;
    }
  }
}
.mobile-menu {
  display: none;
  z-index: 2;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $background-color;
  &.open {
    display: flex;
  }
  .menu {
    .menu-item {
      padding: 0.5em 2em;
      .link {
        text-decoration: none;
        color: $text-color;
        font-size: 2em;
        &.active,
        &:hover {
          color: $color-white;
          font-weight: bold;
        }
      }
    }
  }
}
