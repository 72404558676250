.page.projects {
  min-height: 75vh;
  margin-top: 3em;
  @include media("<=large") {
    margin-top: 0;
  }
  .page-title {
    color: #f5f5f5;
    font-size: 2em;
    position: relative;
    padding-bottom: 15px;
    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 30px;
      background-color: #007ced;
      height: 2px;
      bottom: 0;
    }
  }
  .all-projects {
    margin-top: 2em;
    .project {
      color: $text-color;
      .title {
        font-weight: bold;
        color: $color-white;
      }
      ul {
        list-style: none;
        li {
          display: flex;
          align-items: center;
          &::before {
            content: "\2022";
            color: $color-blue;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5em;
            width: 1em;
          }
          a {
            color: $color-white;
          }
        }
      }
    }
  }
}
