body {
  background-color: $color-blue;
  font-family: poppins, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75em;
  color: $text-color;
  margin-left: 25px;
  margin-right: 25px;
  @include media("<=large") {
    margin: 0;
  }
  &.block-scroll {
    overflow: hidden;
  }
  .site-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $background-color;
    z-index: 4;
    display: none;
    align-items: center;
    justify-content: center;
    &.active {
      display: flex;
    }
  }
  .page-container {
    position: relative;
    display: flex;
    flex-flow: column;
    max-width: 85em;
    margin: 2em auto;
    background-color: $background-color;
    border-radius: 30px;
    padding: 2em;

    @include media("<=large") {
      max-width: 100%;
      margin: 0;
      border-radius: 0;
      height: 100%;
      min-height: 100vh;
    }
    @include media("<=medium") {
      padding-top: calc(2em + 45px);
    }

    .page-content {
      padding-top: 2em;
      @include media(">large") {
        padding-top: 0;
      }
    }
  }
  .hidden {
    display: none;
  }
}
